import React, { useState, useEffect } from 'react';
import './popup.css';
import popupImage from '../popup/popup-image.png';  // Import your local image

const Popup = ({ closePopup }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Start the open animation when the component mounts
        setIsOpen(true);
    }, []);

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="popup-content">
                {/* Use the imported image */}
                <img
                    src={popupImage}
                    alt="Popup"
                    className="popup-image"
                />
                <button className="close-button" onClick={() => {
                    setIsOpen(false);
                    setTimeout(() => closePopup(), 300); // Delay close to match animation
                }}>X</button>
            </div>
        </div>
    );
};

export default Popup;
