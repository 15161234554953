// src/components/Product/product.jsx

import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import ProductCard from '../ProductCard/ProductCard'; // Corrected import path
import './product.css';
import { useNavigate } from 'react-router-dom';

const products = [
  {
    image: 'product.jpg', // Replace with valid URL
    name: 'ROBOTICS ROVER KIT',
    // price: 89.99,
    isSoldOut: false,
    isQuickBuy: false,
  },
  {
    image: 'product3.jpg', // Replace with valid URL
    name: 'JUNIOR ROBOTICS KIT',
    // price: 99.99,
    isSoldOut: false,
    isQuickBuy: false,
  },
  {
    image: 'product6.jpg', // Replace with valid URL
    name: 'DIY ROBOTICS ARM KIT',
    // price: 39.99,
    isSoldOut: true,
    isQuickBuy: false,
  },
  {
    image: 'product7.jpg', // Replace with valid URL
    name: 'DIY ELECTRONICS KIT',
    // price: 39.99,
    isSoldOut: true,
    isQuickBuy: true,
  },
  {
    image: 'product5.jpg', // Replace with valid URL
    name: 'GEOGRAPHY AI & IOT KIT',
    // price: 39.99,
    isSoldOut: true,
    isQuickBuy: true,
  },
  {
    image: 'product.jpg', // Replace with valid URL
    name: 'Botzees GO! - Dino Set',
    // price: 39.99,
    isSoldOut: true,
    isQuickBuy: true,
    // bannerImage: 'product1.jpg',
  },
];

const Product = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is coming from the homepage
    const fromHome = sessionStorage.getItem('fromHome');

    if (fromHome) {
      // If they refreshed, redirect to the homepage
      navigate('/');
    } else {
      // Set a flag in sessionStorage to indicate the user has visited the product page
      sessionStorage.setItem('fromHome', 'true');
    }

    // Cleanup: Remove the flag when the component unmounts
    return () => {
      sessionStorage.removeItem('fromHome');
    };
  }, [navigate]);

  return (
    <div className="featured-section">
      <img
        src="product8.jpg" // Replace with your image URL
        alt="Featured Banner"
        className="featured-image"
      />
      <div className="product-list">
        <Helmet>
          <title>STEM WORLD | Products</title>
        </Helmet>
        <h2 className="product-list-title">Best-Selling Kits for Kids</h2>
        <p className="product-list-subtitle">Grow, develop and play with STEM World </p>
        <div className="product-list-container">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              image={product.image}
              name={product.name}
              // price={product.price}
              isSoldOut={product.isSoldOut}
              isQuickBuy={product.isQuickBuy}
              bannerImage={product.bannerImage}
            />
          ))}
        </div>
      </div>
    </div>

  );
};

export default Product;
