
import React from 'react';
//  import './class.css';

const Classi = () => {
    const categories = [
        {
            title: "Counting and number patterns",
            items: [
                { label: "A.1", description: "Counting review - up to 10" },
                { label: "A.2", description: "Count to fill a ten frame" },
                { label: "A.3", description: "Counting review - up to 20" },
                { label: "A.4", description: "Counting tens and ones - up to 30" },
                { label: "A.5", description: "Count on ten frames - up to 40" },
                { label: "A.6", description: "Counting - up to 100" },
                { label: "A.7", description: "Counting tens and ones - up to 99" },
                { label: "A.8", description: "Counting by twos, fives and tens with pictures" },
                { label: "A.9", description: "Counting by twos, fives and tens" },
                { label: "A.10", description: "Counting forward and backward" },
                { label: "A.11", description: "	Number lines - up to 100" },
                { label: "A.12", description: "Hundred chart" },
                { label: "A.13", description: "	Identify numbers as even or odd" },
                { label: "A.14", description: "Even or odd numbers on number lines" },
                { label: "A.15", description: "Which even or odd number comes before or after?" },
                { label: "A.16", description: "	Skip-counting patterns - with tables" },
                { label: "A.17", description: "	Skip-counting patterns - with tables" },
                { label: "A.18", description: "	Sequences - count up and down by 1, 2, 3, 5 and 10" },
                { label: "A.19", description: "	Sequences - count up and down by 100" },
                { label: "A.20", description: "Ordinal numbers" },
                { label: "A.21", description: "	Writing numbers in words" },
            ]
        },
        {
            title: "Patterns",
            items: [
                { label: "F.1", description: "Add doubles" },
                { label: "F.2", description: "Add using doubles plus one" },
                { label: "F.3", description: "Add using doubles minus one" },
                { label: "F.4", description: "Add three numbers - use doubles" },
                { label: "F.5", description: "Complete the addition sentence - make ten" },
                { label: "F.6", description: "Add three numbers - make ten" },
                { label: "F.7", description: "Add two multiples of ten" },
                { label: "F.8", description: "	Add a multiple of ten" },
                { label: "F.9", description: "Add three numbers" },
                { label: "F.10", description: "Add three numbers - word problems" },
            ]
        },
        {
            title: "Sorting, ordering and classifying",
            items: [
                { label: "D.1", description: "Learn to count - up to 10" },
                { label: "D.2", description: "Count objects - up to 10" },
                { label: "D.3", description: "Count dots - up to 10" },
                { label: "D.4", description: "Count shapes - up to 10" },
                { label: "D.5", description: "Count on ten frames - up to 10" },
                { label: "D.6", description: "Show numbers on ten frames - up to 10" },
                { label: "D.7", description: "Represent numbers - up to 10" }
            ]
        },
        {
            title: "Numbers and counting up to 5",
            items: [
                { label: "G.1", description: "Same" },
                { label: "G.2", description: "Different" },
                { label: "G.3", description: "Same and different" },
                { label: "G.4", description: "Classify shapes by colour" },
                { label: "G.5", description: "Classify and sort by colour" },
                { label: "G.6", description: "Classify and sort by shape" }
            ]
        },
        {
            title: "Positions",
            items: [
                { label: "E.1", description: "Are there enough?" },
                { label: "E.2", description: "More" },
                { label: "E.3", description: "Fewer" },
                { label: "E.4", description: "Fewer and more - compare by counting" },
                { label: "E.5", description: "Compare in a mixed group" }
            ]
        },
        {
            title: "Data and graphs",
            items: [
                { label: "H.1", description: "Colour patterns" },
                { label: "H.2", description: "Size patterns" },
                { label: "H.3", description: "Shape patterns" },
                { label: "H.4", description: "What comes next?" }
            ]
        },
        {
            title: "Numbers and counting up to 10",
            items: [
                { label: "F.1", description: "Inside and outside" },
                { label: "F.2", description: "Above and below" },
                { label: "F.3", description: "Beside and next to" },
                { label: "F.4", description: "Left and right" },
                { label: "F.5", description: "Left, middle and right" },
                { label: "F.6", description: "Top and bottom" },
                { label: "F.7", description: "Top, middle and bottom" }
            ]
        },
        {
            title: "Addition up to 5",
            items: [
                { label: "I.1", description: "Long and short" },
                { label: "I.2", description: "Tall and short" },
                { label: "I.3", description: "Wide and narrow" },
                { label: "I.4", description: "Light and heavy" }
            ]
        },
        {
            title: "Measurement",
            items: [
                { label: "J.1", description: "Coin values" },
                { label: "J.2", description: "Count 1-rupee coins" }
            ]
        }
    ];

    const menuItems = [
        { label: 'L', text: 'Lower kindergarten' },
        { label: 'U', text: 'Upper kindergarten' },
        { label: 'I', text: 'Level I' },
        { label: 'II', text: 'Level II' },
        { label: 'III', text: 'Level III' },
        { label: 'IV', text: 'Level IV' },
        { label: 'V', text: 'Level V' },
        { label: 'VI', text: 'Level VI' },
        { label: 'VII', text: 'Level VII' },
        { label: 'VIII', text: 'Level VIII' },
    ];

    return (
        <div className="maths-container">
            <aside className="menu">
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`menu-item menu-item-${item.label}`}
                        data-text={item.text}
                    >
                        {item.label}
                    </div>
                ))}
            </aside>
            <div className="content-maths">
                {/* ... (keep the rest of the content as is) */}

                <div className="category intro">
                    <h3>Class-I maths</h3>
                    <p>Here is a list of all of the maths skills students learn in UKG! These skills are organised into categories, and you can move your mouse over any skill name to preview the skill. To start practising, just click on any link. STEMWORLD will track your score, and the questions will automatically increase in difficulty as you improve!</p>
                </div>
                <div className="category-list">
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category.title}</h3>
                            <ul>
                                {category.items.map((item, i) => (
                                    <li key={i}>
                                        <span className="item-label">{item.label}</span>
                                        <span className="item-description">{item.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Classi;