import React from 'react'
import Top from '../top/top';
import HeroSection from '../hero-section/hero-section';
import QuarkyTech from '../querky-tech/querky-tech';
import style from './school-program.module.css'
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const SchoolProgram = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is coming from the homepage
        const fromHome = sessionStorage.getItem('fromHome');

        if (fromHome) {
            // If they refreshed, redirect to the homepage
            navigate('/');
        } else {
            // Set a flag in sessionStorage to indicate the user has visited the product page
            sessionStorage.setItem('fromHome', 'true');
        }

        // Cleanup: Remove the flag when the component unmounts
        return () => {
            sessionStorage.removeItem('fromHome');
        };
    }, [navigate]);

    return (
        <div>
            <Helmet>
                <title>STEM WORLD | School-Program</title>
            </Helmet>
            <div className={style.school_program}>.</div>
            <Top />
            <HeroSection />
            {/* <AdvanceConcept /> */}
            {/* <TechnologiesSection /> */}
            <QuarkyTech />
            {/* <WhyQuarkySection /> */}
        </div>
    )
}
